import React, { useEffect } from 'react';

import { IRouter } from '~globalTypes';

import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

type UnsubscribeProps = {
  router: () => IRouter;
};

const Unsubscribe = ({ router }: UnsubscribeProps) => {
  useEffect(() => {
    const unsubscribeEmailDigest = async () => {
      const data = { EmailDigest: false } as { EmailDigest: boolean; Token?: string };
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
      if (token) {
        data.Token = token;
      }

      try {
        await sendRequest(
          'POST',
          '/v0/email-digest',
          {
            data,
            headers: {
              'content-type': 'application/json',
            },
          },
          null,
        );
      } catch (e) {
        if (e) {
          logger.logError('error_unsubscribe_email', e);
        }
      }
    };

    unsubscribeEmailDigest();
  }, []);

  return (
    <div className="container">
      <div className="card-list">
        <div className="card">
          <div className="card-body">
            <div className="title-area text-center">
              <h1 className="display-2 text-danger font-weight-bold mb-2">
                {strings.unsubscribe.goodbye}
              </h1>
            </div>
            <div className="row justify-content-center text-justify">
              <div className="col-9">
                <h4 className="font-weight-bold text-center mt-2">{strings.unsubscribe.content}</h4>
                <div className="text-center">
                  <a href={router().resolve({ path: '/' }).href}>
                    {strings.unsubscribe.goToHomePage}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;
